import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { toKebabCase } from "../helpers";

import style from "../styles/postcard.module.css";

const Postcard = ({
  title,
  date,
  path,
  coverImage,
  author,
  excerpt,
  tags,
  html,
  previousPost,
  nextPost,
  children,
}) => {
  const previousPath = previousPost && previousPost.frontmatter.path;
  const previousLabel = previousPost && previousPost.frontmatter.title;
  const nextPath = nextPost && nextPost.frontmatter.path;
  const nextLabel = nextPost && nextPost.frontmatter.title;

  return (
    <div className={style.post}>
      {coverImage && (
        <Link to={path}>
          <Img
            fluid={coverImage.childImageSharp.fluid}
            fluid={coverImage.childImageSharp.fluid}
            width={coverImage.childImageSharp.fluid.presentationWidth + "px"}
            height={coverImage.childImageSharp.fluid.presentationHeight + "px"}
            maxWidth={coverImage.childImageSharp.fluid.presentationWidth}
            className={style.coverImage}
          />
        </Link>
      )}
      <div className={style.info}>
        <p className={style.title}>
          <Link to={path} aria-label={"Read more about " + style.title}>
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Link>
        </p>

        <p className={style.excerpt}>{excerpt}</p>
        <p className={style.meta}>
          <span className={style.date}>{date}</span>
          {tags ? (
            <span className={style.tags}>
              {tags.slice(0, 3).map(tag => (
                <Link to={`/tag/${toKebabCase(tag)}/`} key={toKebabCase(tag)}>
                  <span className={style.tag}>#{tag}</span>
                </Link>
              ))}
            </span>
          ) : null}
        </p>
      </div>
    </div>
  );
};

Postcard.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  path: PropTypes.string,
  coverImage: PropTypes.object,
  author: PropTypes.string,
  excerpt: PropTypes.string,
  html: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  previousPost: PropTypes.object,
  nextPost: PropTypes.object,
};

export default Postcard;
