import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Seo } from "../components/Seo";
import { Layout } from "../components/Layout";
import Post from "../components/Post";
import { Navigation } from "../components/Navigation";
import Postcard from "../components/Postcard";

const Index = props => {
  const data = props.data;
  const nextPagePath = props.pageContext.nextPagePath;
  const previousPagePath = props.pageContext.previousPagePath;

  const posts = data.allMdx.edges;

  return (
    <>
      <Seo />
      <Layout>
        <main>
          {posts.map(({ node }) => {
            const {
              id,
              excerpt: autoExcerpt,
              frontmatter: {
                title,
                date,
                path,
                author,
                blogIndexIcon,
                blogIndexDescription,
                excerpt,
                tags,
              },
            } = node;

            return (
              <Postcard
                key={id}
                title={title}
                date={date}
                path={path}
                author={author}
                coverImage={blogIndexIcon}
                tags={tags}
                excerpt={blogIndexDescription || autoExcerpt}
              />
            );
          })}
        </main>

        <Navigation
          previousPath={previousPagePath}
          previousLabel="Newer posts"
          nextPath={nextPagePath}
          nextLabel="Older posts"
        />
      </Layout>
    </>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    nextPagePath: PropTypes.string,
    previousPagePath: PropTypes.string,
  }),
};

export const postsQuery = graphql`
  query($limit: Int!, $skip: Int!) {
    allMdx(
      filter: { fileAbsolutePath: { regex: "//posts//" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            date(formatString: "MMMM YYYY")
            path
            author
            excerpt
            blogIndexDescription
            tags
            blogIndexIcon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Index;
