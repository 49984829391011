import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { Navigation } from "./Navigation";
import { toKebabCase } from "../helpers";

import style from "../styles/post.module.css";
import { Newsletter } from "./Newsletter";

const Post = ({
  title,
  date,
  path,
  coverImage,
  author,
  excerpt,
  tags,
  html,
  previousPost,
  nextPost,
  children,
}) => {
  const previousPath = previousPost && previousPost.frontmatter.path;
  const previousLabel = previousPost && previousPost.frontmatter.title;
  const nextPath = nextPost && nextPost.frontmatter.path;
  const nextLabel = nextPost && nextPost.frontmatter.title;

  return (
    <div className={style.post}>
      <div className={style.postContent}>
        <div className={style.meta}>
          <span className={style.date}>{date}</span>
          {tags ? (
            <span className={style.tags}>
              {tags.map(tag => (
                <Link to={`/tag/${toKebabCase(tag)}/`} key={toKebabCase(tag)}>
                  <span className={style.tag}>#{tag}</span>
                </Link>
              ))}
            </span>
          ) : null}
        </div>
        <h1
          className={style.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />

        {coverImage && (
          <Img
            fluid={coverImage.childImageSharp.fluid}
            fluid={coverImage.childImageSharp.fluid}
            width={coverImage.childImageSharp.fluid.presentationWidth + "px"}
            height={coverImage.childImageSharp.fluid.presentationHeight + "px"}
            maxWidth={coverImage.childImageSharp.fluid.presentationWidth}
            className={style.coverImage}
          />
        )}

        {excerpt ? (
          <>
            <p>{excerpt}</p>
            <Link to={path} className={style.readMore}>
              Read more →
            </Link>
          </>
        ) : (
          <>
            {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
            {children && <div> {children} </div>}

            <Navigation
              previousPath={previousPath}
              previousLabel={previousLabel}
              nextPath={nextPath}
              nextLabel={nextLabel}
            />
          </>
        )}
        <Newsletter />
      </div>
    </div>
  );
};

Post.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  path: PropTypes.string,
  coverImage: PropTypes.object,
  author: PropTypes.string,
  excerpt: PropTypes.string,
  html: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  previousPost: PropTypes.object,
  nextPost: PropTypes.object,
};

export default Post;
